import { Logout } from "../../Redux/Reducers/userReducer";
import { useAppDispatch } from "./../../Redux/app/hooks";
import {
  CurrentEnvironment,
  LightBorderLine,
  NextEnvironment,
  SwitchContainer,
  SwitchTitle,
} from "./styles";

const SwitchEnv = () => {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "20px",
        gap: "20px",
      }}
    >
      <LightBorderLine />
      <SwitchTitle>Account Mode</SwitchTitle>
      <SwitchContainer
        href={
          process.env.REACT_APP_API_URL ===
          "https://mainnet.switchwallet.io/api/"
            ? "https://sandbox-integrations.switchwallet.io/"
            : process.env.REACT_APP_API_URL ===
              "https://development.switchwallet.io/api/"
            ? "https://integrations.switchwallet.io/"
            : "https://sandbox-integrations.switchwallet.io/"
        }
        onClick={() => {
          dispatch(Logout());
        }}
      >
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
          title={
            process.env.REACT_APP_API_URL ===
            "https://mainnet.switchwallet.io/api/"
              ? "Switch to sandbox"
              : process.env.REACT_APP_API_URL ===
                "https://development.switchwallet.io/api/"
              ? "Switch  to production"
              : "Switch to sandbox"
          }
        >
          {process.env.REACT_APP_API_URL ===
            "https://mainnet.switchwallet.io/api/" && (
            <>
              <CurrentEnvironment className="text-green">
                Production
              </CurrentEnvironment>
              <NextEnvironment className="text-yellow">Sandbox</NextEnvironment>
            </>
          )}
          {/* {process.env.REACT_APP_API_URL ===
            "https://testnet.switchwallet.io/api/" && (
            <>
              <CurrentEnvironment className="text-yellow">
                Sanbox
              </CurrentEnvironment>
              <NextEnvironment className="text-green">
                Production
              </NextEnvironment>
            </>
          )} */}
          {process.env.REACT_APP_API_URL ===
            "https://development.switchwallet.io/api/" && (
            <>
              <CurrentEnvironment className="text-red">
                Sandbox
              </CurrentEnvironment>
              <NextEnvironment className="text-yellow">Production</NextEnvironment>
            </>
          )}
        </span>
      </SwitchContainer>
    </div>
  );
};

export default SwitchEnv;
